import {Col, Row} from "react-bootstrap";
import hsbc1 from "./img/hasbik.png";
import i1 from "./img/i1.jpg";
import i2 from "./img/i2.jpg";
import i3 from "./img/i3.jpg";
import i4 from "./img/i4.jpg";
import i5 from "./img/i5.jpg";
import i6 from "./img/ii6.jpg";
import v1 from "./vid/v1.mp4";
import v2 from "./vid/v2.mp4";
import v3 from "./vid/v3.mp4";
import v4 from "./vid/v4.mp4";
import v5 from "./vid/v5.mp4";

import translations from "./translations";


export default function Content({lang}) {

    const translation = translations[lang]
    console.log('')
    return <>
        <Row style={{'justifyContent': 'center'}}>
            <Col className={'order-2'} style={{'alignSelf': 'end'}} xs={'12'} md={'8'}>
                <p>
                    {translation[0]}
                </p>
            </Col>
            <Col className={'order-sm-1 order-md-3'} xs={'12'} md={'4'}
                 style={{
                     display: 'flex',
                     justifyContent: "center"
                 }}
            > <img src={hsbc1}/> </Col>

        </Row>
        <Row style={{'margin': '30px 0'}}>
            <Col> <img className={'illustration'} src={i1}/> </Col>
            <Col> <img className={'illustration'} src={i2}/> </Col>
            <Col> <img className={'illustration'} src={i3}/> </Col>
            <Col> <img className={'illustration'} src={i4}/> </Col>
            <Col> <img className={'illustration'} src={i5}/> </Col>
        </Row>
        {translation[1]}
        <Row style={{'alignItems': 'center', justifyContent: 'center'}}>

            <Col xs={'auto'}>
                <img src={i6} className={''} style={{'maxWidth': '800px'}}/>
            </Col>
        </Row>
        <Row style={{'alignItems': 'center', justifyContent: 'center'}}>


            <Col xs={'auto'}>
                <video src={v4} type={'video/mp4'} controls={true}></video>
            </Col>
            <Col xs={'auto'}>
                <video src={v5} type={'video/mp4'} controls={true}></video>
            </Col>
        </Row>
        <p>

            {translation[2]}
        </p>
        <Row style={{'alignItems': 'center', justifyContent: 'center'}}>
            <Col xs={'auto'}>
                <video src={v3} type={'video/mp4'} controls={true}></video>
            </Col>
            <Col xs={'auto'}>
                <video src={v1} type={'video/mp4'} controls={true}></video>
            </Col>
            <Col xs={'auto'}>
                <video src={v2} type={'video/mp4'} controls={true}></video>
            </Col>

        </Row>
        {translation[3]}

        {/*   Ну и тут вы добавите всяких модных, молодежных ссылок на соцсети.*/}

    </>
}