import './App.sass';
import 'bootstrap/dist/css/bootstrap.min.css'
import {Col, Container, Nav, Row, Tab} from 'react-bootstrap'
import Content from './Content'


import fru from './img/flags/ru.png'
import fuk from './img/flags/britain.png'
import fil from './img/flags/il.png'
import {useState} from "react";


function App() {

    let loc = document.location.href.trim()
    if (loc.endsWith('/'))
        loc = loc.substring(0, loc.length - 1)
    let langUrl = loc.split('/').at(-1).toLowerCase()
    if (!['ru', 'en', 'he'].includes(langUrl)) {

        if (navigator.language.includes('ru'))
            langUrl = 'ru'
        else if (navigator.language.includes('he'))
            langUrl = 'he'
        else
            langUrl = 'en'
    }

    const [lang, setLang] = useState(langUrl)

    return (
        <Container>
            <div className={`App ${lang==='he' ? 'he': ''}`}>
                <h1>#BOYCOTTHASBIK</h1>
                <Tab.Container id="left-tabs-example" defaultActiveKey={lang}
                               onSelect={(x) => {
                                   window.history.replaceState('#BOYCOTTHASBIK', '', '/' + x)
                                   setLang(x)
                               }}>
                    <Nav variant="pills">
                        <Nav.Item>
                            <Nav.Link eventKey="en"><img src={fuk} className={'flag'}/> EN</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="ru"><img src={fru} className={'flag'}/> RU</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="he"><img src={fil} className={'flag'}/> HE</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Content lang={lang}/>
                </Tab.Container>
            </div>
        </Container>
    );
}

export default App;
