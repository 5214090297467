export default {
    'ru':
        [
            <> Посмотрите на этого замечательного молодого человека.
                Это <b>Хасбулла́ Магоме́дович Магоме́дов</b>, более известный как <b>Ха́сбик</b>.
                Российский видеоблогер. Родился он 7 июля 2002 г. То есть, несмотря на то, что он выглядит
                как ребенок, это взрослый мужчина.
                Он очень популярен в социальных сетях. Его аккаунт в instagram имеет около 9 миллионов
                подписчиков. Он является амбассадором промоушена смешанных единоборств UFC.
                С ним фотографируются Дана Вайт, Майк Тайсон, Хабиб Нурмагомедов, Ислам Махачев, Хамзат
                Чимаев.</>,
            <>
                <p>
                    Казалось, бы — какой замечательный человек !
                </p>
                <p>

                    Но, в добавок ко всему сказанному, Хасбик является ярым антисемитом.
                    7 октября 2023 года в своих соцсетях он поддержал ХАМАС в их диком нападении на Израиль. Нападении,
                    в котором были убиты сотни мирных людей. Стариков, женщин, детей, младенцев !
                    Хасбику это очень понравилось !
                </p>
                <p>

                    Позднее, он опубликовал в своем телеграм-канале призывы не сдавать и не продавать квартиры евреям.
                    А 23 октября 2023 года он под угрозой поджога требовал от персонала гостиницы «Фламинго» в городе
                    Хасавюрте выселить из всех евреев и призывал местное население устроить погром. Разъяренные люди
                    пришли к отелю и стали искать там евреев. К счастью, никого не нашли. Только поэтому никто не
                    пострадал.
                </p>
            </>,
            <>Хасбик своими постами разогревал ненависть к евреям в Дагестане, и он один из тех, кто ответственен
                за захват аэропорта в Махачкале.</>,
            <>
                <p>
                    Российская прокуратура уже проверяет действия Хасбика на экстремизм
                    <sup>
                        <a href={'https://www.mk.ru/incident/2023/10/31/blogera-khasbika-proveryat-na-ekstremizm-posle-besporyadkov-v-makhachkale.html'}>[1]</a>
                        <a href={'https://ura.news/news/1052699456'}>[2]</a>
                    </sup>.
                    Но, к сожалению, вероятность того, что он понесет ответственность, крайне мала.
                </p>
                <p>
                    В современном мире <b>неприемлемы</b> никакие проявления ненависти по национальному признаку,
                    признаку расовой принадлежности или сексуальной ориентации.
                </p>
                <p>

                    Поэтому мы хотим обратить внимание руководства UFC к тому, что позволяет себе их амбассадор. И
                    считаем, что любое сотрудничество с подобными националистами должно быть прекращено. Просим вас
                    помочь нам в распространении этой информации. Используйте хештэг #boycotthasbik чтобы привлечь
                    внимание к этой проблеме.
                </p></>

        ],
    'en':
        [
            <> Take a look at this cute young man.
                This is <b>Khasbullaa Magomedovich Magomedov</b> better known as <b>Khasbik</b>.
                Russian video blogger. He was born on July 7, 2002. So despite the fact he looks like a child he is an
                adult man.
                He is very popular on social media. There are about 9 million followers on his Instagram account.
                He is the ambassador for the UFC mixed martial arts promotion.
                Dana White, Mike Tyson, Khabib Nurmagomedov, Islam Makhachev, Connor McGregor are photographed with
                him.
            </>,
            <>
                <p>
                    It would seem what a wonderful person he is!
                </p>
                <p>
                    But in addition to all has been said Hasbik is an ardent anti-Semite.
                    On October 7, 2023 he used his social media to support HAMAS in their savage attack on Israel.
                    The attack when thousands of civilians were killed. Elderly people, women, children, babies!
                    Hasbik really liked it!

                </p>
                <p>

                    Later he published calls on his Telegram channel not to rent or sell apartments to Jews.

                    On October 23, 2023 under the threat of arson he demanded the staff of the “Flamingo” hotel in the
                    city of Khasavyurt to evict all Jews.
                    He also called on the residents of Khasavyurt to crack down on Jews.
                    Furious people came to the hotel and started to look for Jews there. Fortunately no one was found.
                    So that's the only reason no one was hurt.


                </p>
            </>,
            <>
                In his own words he contributed to the incitement of hatred against Jews in Dagestan. He is one of those
                who responsible for the seizure of the airport of Makhachkala on October 29, 2023.</>,
            <>
                <p>
                    The Russian prosecutor's office is already checking Hasbik's actions for extremism.
                    <sup>
                        <a href={'https://www.mk.ru/incident/2023/10/31/blogera-khasbika-proveryat-na-ekstremizm-posle-besporyadkov-v-makhachkale.html'}>[1]</a>
                        <a href={'https://ura.news/news/1052699456'}>[2]</a>
                    </sup>.
                    But unfortunately the likelihood he will be punished is extremely low.
                    Moreover currently hi is living in UAE thus, he is inaccessible for the Russian law studio.
                </p>
                <p>
                    Any manifestations of hatred based on nationality, race or sexual orientation
                    are <b>unacceptable</b> in today's world.

                </p>
                <p>
                    Therefore we want to draw the attention of the UFC management to what their ambassador allows
                    himself to do. And we believe that any cooperation with such nationalists should be stopped.
                    We ask your help to spread this information.
                    Tell your friends about it. Use hashtag <b>#boycotthasbik</b>

                </p></>

        ],
    'he':
        [
            <>תסתכל על הצעיר החמוד הזה. זהו <b>Khasbullaa Magomedovich Magomedov</b> הידוע יותר בשם <b>Khasbik</b>. בלוגר וידאו רוסי.
                הוא נולד ב-7 ביולי 2002. אז למרות העובדה שהוא נראה כמו ילד הוא גבר בוגר. הוא מאוד פופולרי ברשתות
                החברתיות. בחשבון האינסטגרם שלו יש כ-9 מיליון עוקבים. הוא השגריר של קידום אומנויות לחימה מעורבות ב-UFC.
                דנה ווייט, מייק טייסון, חביב נורמגומדוב, איסלאם מחצ'וב, קונור מקגרגור מצולמים איתו.
            </>,
            <>
                <p>
                    זה נראה איזה אדם נפלא הוא!
                </p>
                <p>
                    אבל בנוסף לכל מה שנאמר חסביק הוא אנטישמי נלהב.
                    ב-7 באוקטובר 2023 הוא השתמש ברשתות החברתיות שלו כדי לתמוך בחמאס במתקפה הפרועה שלהם על ישראל.
                    הפיגוע כאשר אלפי אזרחים נהרגו. קשישים, נשים, ילדים, תינוקות!
                    הסביק מאוד אהב את זה!

                </p>
                <p>

                    מאוחר יותר פרסם קריאות בערוץ הטלגרם שלו שלא להשכיר או למכור דירות ליהודים.

                    ב-23 באוקטובר 2023, תחת איום בהצתה, הוא דרש את הצוות של מלון "פלמינגו" ב-
                    העיר Khasavyurt לגרש את כל היהודים.
                    הוא גם קרא לתושבי חסאוויורט לפעול נגד יהודים.
                    אנשים זועמים הגיעו למלון והחלו לחפש בו יהודים. למרבה המזל איש לא נמצא.
                    אז זו הסיבה היחידה שאף אחד לא נפגע.


                </p>
            </>,
            <>
                במילותיו שלו הוא תרם להסתה לשנאה נגד יהודים בדאגסטן. הוא אחד מאלה
                מי שאחראי לתפיסת שדה התעופה של מחצ'קלה ב-29 באוקטובר 2023.</>,
            <>
                <p>
                    הפרקליטות הרוסית כבר בודקת את פעולותיו של חסביק לקיצוניות.
                    <sup>
                        <a href={'https://www.mk.ru/incident/2023/10/31/blogera-khasbika-proveryat-na-ekstremizm-posle-besporyadkov-v-makhachkale.html'}>[1]</a>
                        <a href={'https://ura.news/news/1052699456'}>[2]</a>
                    </sup>.
                    אבל למרבה הצער הסבירות שהוא ייענש נמוכה ביותר.
                    יתרה מכך, כרגע היי מתגורר באיחוד האמירויות, ולכן הוא אינו נגיש עבור הסטודיו למשפטים הרוסי.</p>
                <p>
                    כל גילויי שנאה על בסיס לאום, גזע או נטייה מינית
                    הם <b>לא מקובלים</b> בעולם של היום.

                </p>
                <p>
                    לכן אנחנו רוצים להסב את תשומת הלב של הנהלת ה-UFC למה שהשגריר שלהם מאפשר
                    את עצמו לעשות. ואנחנו מאמינים שצריך להפסיק כל שיתוף פעולה עם לאומנים כאלה.
                    אנו מבקשים את עזרתכם להפיץ מידע זה.
                    ספר על זה לחברים שלך. השתמש בהאשטאג

                </p><h3 style={{'textAlign':'center','direction': "initial",'fontSize':'28px'}}><b className={'unrtl'}>#boycotthasbik</b></h3></>

        ]
}
